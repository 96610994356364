
export default {
	name: 'Home',
	created () {
		this.$nextTick(function () {
			// localStorage.clear()
			// localStorage.removeItem('')
			this.GetCarousel()
			this.GetFrequentlyAskedQuestions()

		})
	},
	mounted () {
		this.$nextTick(function () {

		})
	},
	data () {
		return {
			CarouselArr: [],
			CarouselArrID: 0,

			QuestionsArr: [],
			ArrangeActive6: null,
			ArrangeActive6Of: false
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取Carousel的数据
		GetCarousel: function () {
			let _this = this
			$.ajax({
				type: "post",
				url: process.env.VUE_APP_API + "GetCarousel",
				data: { token: "webkey" },
				dataType: "json",
				success: data => {
					if (data.status === "SUCCESS") {
						this.CarouselArr = data
						setTimeout(() => {
							$('#slides').on('slide.bs.carousel', (e) => {
								this.CarouselArrID = e.to
							})
						}, 10)

					}
				},
				error: function (error) {
					console.log(error);
				}
			});
		},
		// 获取Questions的数据
		GetFrequentlyAskedQuestions: function () {
			let _this = this
			$.ajax({
				type: "post",
				url: process.env.VUE_APP_API + "GetFrequentlyAskedQuestions",
				data: { token: "webkey" },
				dataType: "json",
				success: data => {
					if (data.status === "SUCCESS") {
						$.each(data.text, (i, c) => {
							if (c.highlight == 1) {
								this.QuestionsArr.push(c)
							}
						})
					}
				},
				error: function (error) {
					console.log(error);
				}
			});
		},
		ArrangeActive6Clcik (active) {
			this.ArrangeActive6 = active
			this.ArrangeActive6Of = !this.ArrangeActive6Of
			$('#Home').find('.Box6').find('.Arrange').find('li').eq(active).siblings().find('.Text_a').slideUp()
			$('#Home').find('.Box6').find('.Arrange').find('li').eq(active).find('.Text_a').slideToggle()
		}
	},
	components: {

	}
}
